import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Row, Col, ProductBookCard, Button, WhatNext } from '../../components/global'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const SixtyLessons = () => (
  <Layout>
    <SEO title="The 60 Lesson Course" />
    <div className="container container-last">
      <h1>The 60 Lesson Course</h1>
      <p className="boost">
        A comprehensive electric theory course designed to teach you how to troubleshoot and repair
        today's vehicles.
      </p>
      <p>
        This course explains troubleshooting and repair of electrical-electronic problems
        encountered in cars, trucks and heavy equipment.
      </p>
      <p>
        Lessons show you how these circuits work, how they fail and how to test them with a DMM,
        Current Clamp, Logic Probe and Lab Scope to identify the problem. This course will teach you
        to become what we call a "V.E.T." (a Vehicle Electronics Technician).
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The 60 Lesson Course"
            step="Lessons"
            animate={false}
            price={249.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p className="boost">There are 2 purchase options for The 60 Lesson Course:</p>
          <p>
            First, you can purchase all 60 lessons digitally at our website dedicated to The 60
            Lesson Course.
          </p>
          <p>
            <strong>This is the best option for those who want to save shipping costs.</strong>
          </p>
          <p>
            <Button
              href="https://training.veejer.com/?utm_source=veejer.com&utm_medium=website&utm_campaign=60-lesson-buy-button"
              text="Buy The 60 Lesson Course digitally - $249"
              type="secondary"
            />
            <span className="meta button-meta">
              All 60 lessons are available at the above website after a one-time purchase. Access is
              granted for life. You will create an account, purchase access, and be able to view the
              lessons at any time on your computer or from your mobile device after logging in.
            </span>
          </p>
          <p>
            Second, you can purchase all 60 lessons in printed form, shipping charges will apply and
            are calculated after checkout.
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=fc3d8d3cf5fb489881f479327554286c"
              text="Buy The 60 Lesson Course in print - $299"
              type="secondary"
            />
            <span className="meta button-meta">
              Shipping to US postal codes is estimated at $14. For international shipping we'll
              email you after your order with a quote for approval and final confirmation. Persons
              outside the US may find purchasing the lessons digitally to be more cost-effective.
            </span>
          </p>
        </Col>
      </Row>
      <hr />
      <p>
        "The" 60 Lesson Course starts with an introduction to electrical circuits, vehicle
        electrical system troubleshooting with DMMs (voltmeters, ammeters and ohmmeters) and the DMM
        readings to expect in properly operating vehicle electrical circuits (lighting circuits,
        cranking circuits, charging circuits and other circuits such as DC Motor, solenoid and relay
        circuits.
      </p>
      <p>
        Students learn to read schematic diagrams, understand solid-state electronic circuit
        operation (diodes, transistors, transistor drivers &amp; integrated circuits). An
        introduction to digital electronics &amp; logic gate circuits, computer principles &amp;
        troubleshooting vehicle computer sensors &amp; actuator circuits is also covered.
      </p>
      <p>
        Every fourth lesson contains a Review Test of the previous four lessons. Answers are
        provided at the end of each review test. No need to send tests back for grading. Proceed
        through the 60 lessons at your own pace.
      </p>
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lessons 1-12 Overview</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>You can buy Lessons 1-12 only, in print.</p>
            <p>
              <Button
                href="http://www.1shoppingcart.com/app/netcart.asp?merchantID=177&PRODUCTID=22711"
                text="Buy Lessons 1-12 only - $69"
                type="secondary"
              />
              <span className="meta button-meta"></span>
            </p>
            <hr />
            <p>
              <strong>Lesson 1 [4 Pages.]</strong>
              <br />
              ATOMIC STRUCTURE AND INTRODUCTION TO VEHICLE ELECTRICAL &amp; ELECTRONIC SYSTEMS
              Introduction to differences between vehicle electrical systems and electronic systems;
              introduction to the atom and elements; what is electricity; electrons; nucleus,
              protons and neutrons; The Law of Electrostatics; basic atoms; conductor and insulator
              atoms; direction of current flow; when an electrical circuit become an electronic
              circuit.
            </p>
            <p>
              <strong>Lesson 2 [4 Pages.]</strong>
              <br /> ELECTRICAL CHARGES The First Law of Charges; nature of negative and positive
              charges; potential difference; what happens when current flows; potential difference
              between unlike charges and similar charges; battery used to maintain charge,
              discharging a battery; Voltage; Units of Volts; relationship between battery and
              alternator.
            </p>
            <p>
              <strong>Lesson 3 [4 Pages.]</strong>
              <br />
              VOLTAGE &amp; VOLTMETERS CURRENT &amp; AMMETERS Definition of voltage; direct
              relationship of voltage and current; SLI battery voltage; Open Circuit Voltage
              measurement; Analog and digital voltmeters; voltmeter DC accuracy; basic principle of
              voltmeter operation; measuring open circuit voltage; DC current from a battery;
              ammeters measure current.
            </p>
            <p>
              <strong>Lesson 4 [4 Pages.]</strong>
              <br />
              RESISTANCE, PART 1 Definition of resistance, indirect relationship of current and
              resistance; the unit of resistance; expressing resistance; resistance in conductive
              material; resistance of wire; specific resistance; relative resistance values;
              specific resistance of materials; calculating resistance of wire; American Wire Gauge;
              specific resistance of solid-state material; resistor notation and symbol; fixed
              carbon resistors; how temperature affects resistance.
            </p>
            <p>Review Test 1-4 (28 Questions)</p>
            <p>
              <strong>Lesson 5 [4 Pages.]</strong>
              <br />
              RESISTANCE, PART 2 The purpose of resistors; The Carbon Composition Resistor;
              Connecting resistors to circuit boards; definition of soldering; common problem
              mounting resistors and the dog-bone resistor; the schematic symbol of the resistor and
              resistance; evaluating resistors in terms of ohm value, tolerance rating, temperature
              stability and power rating; resistor size in terms of physical size, wire wound
              resistors.
            </p>
            <p>
              <strong>Lesson 6 [6 Pages.]</strong>
              <br />
              RESISTANCE, PART 3 Resistor color code, labeling resistors on the circuit board,
              determine resistance from the color code, determine color code from the resistance,
              multiplier factor, tolerance factor, calculating tolerance values, practice exercise
              calculating tolerance, Meg ohms, kohms, resistor circuits, the series circuit, the
              parallel circuit, the series-parallel compound circuit, cold circuit analysis,
              practice exercise working with the resistor color code.
            </p>
            <p>
              <strong>Lesson 7 [7 Pages.]</strong>
              <br />
              RESISTANCE PART 4 SERIES and PARALLEL RESISTOR CIRCUITS [5 Pages. plus 1.5 pg.
              supplement] Calculating resistance in series circuits, total resistance in series,
              total resistance in parallel, three ways to calculate total resistance in parallel,
              total resistance in compound circuits.
            </p>
            <p>
              <strong>Lesson 8 [4 Pages.]</strong>
              <br />
              ANALOG AND DIGITAL OHMMETERS Introduction to ohmmeters, how an ohmmeter works, the
              ohmmeter scale, analog ohmmeter ranges, calibrating analog ohmmeters, digital
              ohmmeters, differences in analog and digital ohmmeters, ohmmeter test voltage polarity
              and amplitude, F.E.T. analog ohmmeter characteristics.
            </p>
            <p>Review Test 5-8 (33 Questions)</p>
            <p>
              <strong>Lesson 9 [6 Pages.]</strong>
              <br />
              TROUBLESHOOTING WITH OHMMETERS Selecting the type ohmmeter, measuring resistor
              circuits, small battery symbol for a test battery, connecting the ohmmeter test leads,
              tracing ohmmeter test current, indicating the resistance value, reversing ohmmeter
              test leads, analyzing analog ohmmeter readings, when resistance is lower than normal,
              definition of a "short," definition of an "open" or high resistance, using an ohmmeter
              to check continuity, checking resistance in parallel circuits.
            </p>
            <p>
              <strong>Lesson 10 [6 Pages.]</strong>
              <br />
              WORKING WITH RESISTANCE IN VEHICLE CIRCUITS Working with resistance, incandescent lamp
              resistance, two factors to know about lamps, incandescent lamp "cold" resistance,
              incandescent lamp "hot" resistance, determining lamp hot resistance, resistance from
              friction, switch contact resistance, summarizing ohmmeters.
            </p>
            <p>
              <strong>Lesson 11 [6 Pages.]</strong>
              <br />
              VEHICLE CIRCUIT PRINCIPLES "The Voltage Side of the Circuit" Building the basic
              circuit, Modes of vehicle voltage source operation. Basic Vehicle Equivalent Circuit,.
              System voltage in the BVEC, Current flow in the BVEC, Battery current, How current
              flows inside the battery, Current flow with the alternator on-line, BVEC circuit
              resistance, major points to remember.
            </p>
            <p>
              <strong>Lesson 12 [6 Pages.]</strong>
              <br />
              "The Ground Side of the Circuit" The BVEC without grounds, Ground, Ground in a
              vehicle, Ground symbols on schematic diagrams, Negative ground systems, Ground side
              resistance, Positive ground systems, Polarity of the load, Ground circuit resistance,
              ground circuit voltage, Voltage above and below ground, Pop Quiz, The proper way to
              measure circuit voltage.
            </p>
            <p>Review Test 9-12 (13 Questions)</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lessons 13-24 Overview</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>You can buy Lessons 13-24 only, in print.</p>
            <p>
              <Button
                href="http://www.1shoppingcart.com/app/netcart.asp?merchantID=177&PRODUCTID=22712"
                text="Buy Lessons 13-24 only - $69"
                type="secondary"
              />
              <span className="meta button-meta"></span>
            </p>
            <hr />
            <p>
              <strong>Lesson 13 [6 Pages.]</strong>
              <br />
              INTRODUCTION TO OHM'S LAW, THE METRIC SYSTEM &amp; POWER MEASUREMENT Introduction;
              Ohm's Law in basic form; Metric system of measurement; Ohm's Law used throughout
              electronics; Ohm's Law defined; Ohm's Law Pie; Metric Units of Voltage Measurement;
              practice with Ohm's Law for voltage; a common mistake using Ohm's Law; Use Ohm's Law
              to calculate current; Metric units of current measurement, convert amps to milliamps
              or milliamps to amps; practice with Ohm's Law for current; use Ohm's Law to calculate
              resistance; practice with Ohm's law for resistance; Ohm's Law applies to the total
              circuit; power in electrical circuits; metric units of power measurement; practice
              with the power formula; answers to practice exercises. [Supplement 13-1 Reference
              Chart..
            </p>
            <p>
              <strong>Lesson 14 [6 Pages.]</strong>
              <br />
              SERIES CIRCUIT ANALYSIS Using Ohm's Law; updating the BVEC; total circuit values;
              voltage source variations; two basic configurations; current in a series circuit;
              voltage in a series circuit; detrimental effects of RVS and RGS; working with current
              and voltage in series; answers to practice problems.
            </p>
            <p>
              <strong>Lesson 15 [4 Pages.]</strong>
              <br />
              VOLTAGE DROP MEASUREMENTS Introduction to voltage drops; what do you mean by a voltage
              drop?; good and bad voltage drops; the math of voltage drops; what makes voltage drops
              appear?; normal voltage drops; small resistance makes big voltage drops; two
              limitations of ohmmeters.
            </p>
            <p>
              <strong>Lesson 16 [6 Pages.]</strong>
              <br />
              APPLIED VOLTAGE DROPS Typical voltage drop readings; voltage drops in wiring; voltage
              drops in connections; voltage drops in battery cables; voltage drops in battery posts;
              voltage drops in ground circuits; proper grounding of a DMM; two primary vehicle
              ground Vds; Vd of voltage and ground side of a circuit; voltage drops - alternator and
              battery; voltage drops - battery &amp; starter motor.
            </p>
            <p>Review Test 13-16 (33 Questions)</p>
            <p>
              <strong>Lesson 17 [6 Pages.]</strong>
              <br />
              VOLTAGE AND CURRENT IN PARALLEL CIRCUITS Introduction to parallel circuits; The
              Parallel Circuit; Review of the laws of series circuits; Introduction to Voltage and
              Current In Parallel; The Law of Voltage in Parallel; Using the Law of Voltage in
              Parallel; The Law of Current in Parallel; Using the Law of Current in Parallel;
              Tracing Current in a Parallel Circuit; Measuring Key-Off Drain; Kirchhoff's Laws;
              Applying Kirchhoff's Current Law; Applying Kirchhoff's Voltage Law; Analyzing Circuit
              Loops; RVS and RGS in Parallel Circuits; Jump Starting Creates a Parallel Circuit.
            </p>
            <p>
              <strong>Lesson 18 [6 Pages.]</strong>
              <br />
              COMPOUND CIRCUIT ANALYSIS &amp; INTRO. TO TROUBLESHOOTING Introduction; The Series
              Circuit; The Parallel Circuit; Cold Circuit Analysis; Practice Exercises Using Figure
              3; The Compound Circuit; Practice Exercise Using Figure 4; Electronic Troubleshooting
              By Definition; How To Begin Troubleshooting; Two Techniques of Voltage Measurement;
              Techniques of Current Measurement; Techniques of Resistance Measurements.
            </p>
            <p>
              <strong>Lesson 19 [6 Pages.]</strong>
              <br />
              BATTERIES, PART 1 OF 2 "BATTERY PRINCIPLES" Why test batteries?; what is VET battery
              trouble-shooting?; battery construction; battery voltage; battery operation; specific
              gravity; surface charge; specific gravity vs. OCV; adding water to batteries; charging
              batteries; how to tell when battery is fully charged; storing batteries on concrete;
              battery chemistry; two battery specifications are important; when a VET installs a new
              battery; misc. battery safety tips.
            </p>
            <p>
              <strong>Lesson 20 [6 Pages.]</strong>
              <br />
              BATTERIES, PART 2 OF 2 "TESTING BATTERIES" Overview of battery testing; test equipment
              for battery testing; crank test the battery in the vehicle; BATTERY TEST FOR
              DISCHARGE; determine remaining battery service life; BATTERY TEST FOR CHARGE; common
              ways SLI batteries fail. Supplement 20-1 - Vehicle Battery Standard Test Procedure for
              VETs.
            </p>
            <p>Review Test 17-20 (39 Questions)</p>
            <p>
              <strong>Lesson 21 [6 Pages.]</strong>
              <br />
              ALTERNATORS AND CHARGING SYSTEMS PART 1 OF 2 Introduction to Alternators; The
              Alternator or Charging System; The Charging Voltage; The Charging Current; The Voltage
              Regulator; Regulating the Charging Voltage; Rotor and Stator Windings; AC Inside the
              Alternator; AC Changed to DC; Regulating Charging Current.
            </p>
            <p>
              <strong>Lesson 22 [6 Pages.]</strong>
              <br />
              ALTERNATORS - CHARGING SYSTEM TROUBLESHOOTING, PART 2 OF 2 The Diode Trio; The
              Electronic Feedback Principle; Alternator Specifications; Vds in the Charging System;
              When VdVS and VdGS are Slightly Too High; Alternator Noise; AM/FM Radio Detects
              Alternator Noise; Battery Should Be Fully Charged When Installed; Testing the Charging
              System; Charging System Characteristics; Testing the Charging Voltage; Voltage Test
              for Undercharge; Voltage Test for Undercharge; Testing the Charging Current; Final
              Item About Charging Systems; New Horizons with Alternator.
            </p>
            <p>
              <strong>Lesson 23 [6 Pages.]</strong>
              <br />
              LOAD CIRCUIT CONTROL The Vehicle's Voltage Source; BVEC Series Lamp Circuit; Load
              Control; The Texas-Two-Step; Switch-to-Voltage Control; Vc with Switch-to-Voltage
              Control; Switch-to-Ground Control; Vc with Switch-to-Ground Control; Dynamic Load
              Resistance; In-Rush Current, Switch Control in Parallel Circuits; Fail-Safe; Practice
              Exercise.
            </p>
            <p>
              <strong>Lesson 24 [6 Pages.]</strong>
              <br />
              BASIC CIRCUIT FAULT ANALYSIS What Goes Wrong in a Circuit?; The Texas-Two-Step; Effect
              of VdLOAD on the Circuit; Six Common Circuit Problems; Circuit Problem #1; Definition
              of an Open Circuit; Circuit Problem #2; Definition of a Vd; What Happens When the Load
              is Disconnected?; Circuit Problem #3; Circuit Problem #4; Circuit Problem #5; Circuit
              Problem #6.
            </p>
            <p>Review Test 21-24 (24 Questions)</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lessons 25-36 Overview</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>You can buy Lessons 25-36 only, in print.</p>
            <p>
              <Button
                href="http://www.1shoppingcart.com/app/netcart.asp?merchantID=177&PRODUCTID=22713"
                text="Buy Lessons 25-36 only - $69"
                type="secondary"
              />
              <span className="meta button-meta"></span>
            </p>
            <p>
              <strong>Lesson 25 [4 Pages]</strong>
              <br />
              TROUBLESHOOTING SHORT CIRCUITS Introduction to short circuits; What is a short?; BVEC
              Circuit; Testing total resistance;; Evaluating CUT RT; Shorted Load; Low resistance
              Load; Voltage side short to ground; Short to ground, CUT OK; Sample Readings on
              vehicles.
            </p>
            <p>
              <strong>Lesson 26 [4 Pages]</strong>
              <br />
              CAPACITORS, Part 1 of 2 Overview of electronic components; Introduction to capacitors;
              Capacitors in DC versus AC circuits; Charging capacitors with DC; Polarity of
              capacitor charge; Quantity of capacitor charge; Size of capacitor plates affects
              charge; Characteristics of dielectric materials.
            </p>
            <p>
              <strong>Lesson 27 [6 Pages]</strong>
              <br />
              CAPACITORS PART 2 OF 2 The charged capacitor; Dielectric leakage; A capacitor holds a
              charge; How a capacitor discharges, Capacitor action in a live circuit; S1 closed, S2
              closed; Capacitors react to increased source voltage; Capacitors react to decreased
              source voltage; Turning circuit OFF with capacitors; Capacitor values; Formula for
              capacitance; Capacitors and time; RC time constants; Time constants while charging;
              Time constants while charging; Types of capacitors; Electrolytic capacitors;
              Capacitors mounted on circuit boards.
            </p>
            <p>
              <strong>Lesson 28 [6 Pages]</strong>
              <br />
              INDUCTORS AND INDUCTANCE, PART 1 Review of an electrostatic field; What is an
              inductor; Inductors compared to capacitors; Current through a wire; Use care with an
              inductive current clamp; Properties of magnets; Dynamics of a coil; Coil symbols;
              Inductor designations; Effect of Inductor at circuit power-up; When a coil is not
              present; When coil is present; Effect of inductor at max current; Effect of inductor
              as source voltage varies; Effect of inductor at circuit power down; Electronics
              circuits need surge protection; Inductance measured in Henries.
            </p>
            <p>REVIEW TEST 25-28 (18 Questions)</p>
            <p>
              <strong>Lesson 29 [4 Pages]</strong>
              <br />
              INDUCTORS, PART 2 Inductors in circuits; Inductive characteristics; Inductance and
              current; Inductance and resistance; Inductance and core material; Inductance and
              number of turns; Troubleshooting Intermittent Coils; Inductor Time Constants; Coil
              Discharging and Time Constants.
            </p>
            <p>
              <strong>Lesson 30 [4 Pages]</strong>
              <br />
              INDUCTORS, PART 3 The good and the bad about inductors; energizing a coil; Battery
              voltage decreases while cranking; De-energizing a coil; Surge current path; How a coil
              dumps stored energy so fast; Protecting solid-state computer circuits.
            </p>
            <p>
              <strong>Lesson 31 [6 Pages]</strong>
              <br />
              CAPACITORS &amp; INDUCTORS WORKING TOGETHER Capacitors and inductors work together;
              Reviewing capacitors; Reviewing inductors; Radio noise filtering; Radio noise filter
              theory; Coil filters current variations; Capacitor filters voltage variations;
              Ignition circuit; When the points are OPEN; When the points are CLOSED; When the
              points are OPENED; What is a transformer?; Firing the plug; Quieting switch pop.
            </p>
            <p>
              <strong>Lesson 32 [4 Pages]</strong>
              <br />
              COIL CIRCUITS, PART 1 OF 2 Where we are up to now; When a coil is a load in a circuit;
              Coil (Load) reaction to circuit current; Coil load resistance readings; Reviewing
              ohmmeters; Measuring coil resistance; Measuring coil circuit current; Measuring coil
              circuit voltages.
            </p>
            <p>REVIEW TEST 29-32 (25 Questions)</p>
            <p>
              <strong>Lesson 33 [6 Pages]</strong>
              <br />
              COIL CIRCUITS, PART 2 TROUBLESHOOTING SOLENOID CIRCUITS Overview; Measuring coil
              circuit Vds; Controlling a coil as the load; Switch-to-ground control; TTS readings
              with S-G-C; Try the TTS on an A/C Clutch on a car; Six basic coil circuit failures;
              Coil circuit failure #1; Troubleshooting coil problem #1; Coil circuit failure #2;
              Troubleshooting coil problem #2; When load is disconnected from circuit; Coil circuit
              failure #3; Troubleshooting coil problem #3; Coil circuit failure #4; Troubleshooting
              failure #4; When load is disconnected from circuit; Coil circuit failure #5;
              Troubleshooting failure #5; Coil circuit failure #6; Troubleshooting failure #6; Coils
              and ballast resistors.
            </p>
            <p>
              <strong>Lesson 34 [6 Pages]</strong>
              <br />
              PRINCIPLES OF DC MOTORS Lesson overview; DC motor component parts; Current in DC motor
              operation; DC motor operational characteristics; What is CEMF?; DC motor symbols; DC
              motor configurations; Starter motor circuit; Interpreting starter motor circuits; Low
              voltage-yet normal current?
            </p>
            <p>
              <strong>Lesson 35 [6 Pages]</strong>
              <br />
              TROUBLESHOOTING DC MOTOR CIRCUITS Lesson overview;, When a DC motor is the load in a
              circuit; DC motor reaction to circuit current; DC motor winding resistance; Check DC
              motor for shorts to housing; Measuring DC motor load current; Calculating DC motor
              resistance when ON; Measuring DC motor circuit voltages; Measuring DC motor voltage
              drops; DC motor ballast resistors; DC blower motor circuit; Troubleshooting a blower
              motor problem.
            </p>
            <p>
              <strong>Lesson 36 [6 Pages]</strong>
              <br />
              DC MOTOR CIRCUIT COMMON FAILURE MODES PART 1 OF 2 Lesson overview; Controlling a DC
              motor; Switch-to-voltage DC motor Control; TTS readings with S-V-C; TTS readings with
              S-G-C; Perform TTS on a DC blower motor circuit; DC motor circuit failures; DC motor
              circuit failure #1; DC motor circuit failure #2; DC motor circuit failure #3; DC motor
              circuit failure #4.
            </p>
            <p>REVIEW TEST 33-36 (19 Questions)</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lessons 37-48 Overview</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>You can buy Lessons 37-48 only, in print.</p>
            <p>
              <Button
                href="http://www.1shoppingcart.com/app/netcart.asp?merchantID=177&PRODUCTID=22714"
                text="Buy Lessons 37-48 only - $69"
                type="secondary"
              />
              <span className="meta button-meta"></span>
            </p>
            <p>
              <strong>Lesson 37 [6 Pages]</strong>
              <br />
              DC MOTOR CIRCUIT COMMON FAILURE MODES PART 2 OF 2 DC motor circuit failure #5; DC
              motor circuit failure #6; DC motor is shorted; Voltage side of circuit shorted to
              ground; DC motor winding shorted to housing; DC motor controlling excessive load;
              Benefits of DC motor load testing; When 2 problems exist at the same time.
            </p>
            <p>
              <strong>Lesson 38 [4 Pages]</strong>
              <br />
              RELAY CIRCUITS, PART 1 OF 4 Lesson introduction; The purpose of relays; Common relay
              terminal description; A coil polarity problem; Relay spike suppression resistor;
              Introduction to troubleshooting relays, BVEC relay circuit.
            </p>
            <p>
              <strong>Lesson 39 [6 Pages]</strong>
              <br />
              RELAY CIRCUITS, PART 2 OF 4 Lesson introduction; Relay DC circuit operation; Relay
              terminology; CCA or relay's primary circuit; CCA of relay's secondary circuit; A
              system for troubleshooting relays; Troubleshooting relay primary circuit'
              troubleshooting relay secondary circuit; Relay coil reaction to primary circuit;
              Importance of relay winding resistance; Check coil resistance - digital ohmmeter;
              Reviewing analog ohmmeters; Check coil resistance - analog ohmmeter.
            </p>
            <p>
              <strong>Lesson 40 [4 Pages]</strong>
              <br />
              RELAY CIRCUITS, PART 3 OF 4 Checking diodes with diode test; Will diode test always
              check a diode?; Relays with spike suppression resistors; measuring relay coil current;
              measuring relay circuit voltage drops; Vds of the primary circuit; Vds of the
              secondary circuit; Checking relay contact Vd.
            </p>
            <p>REVIEW TEST 37-40 (13 Questions)</p>
            <p>
              <strong>Lesson 41 [6 Pages]</strong>
              <br />
              RELAY CIRCUITS, PART 4 OF 4 Finishing up relay circuits; Switch-to voltage relay
              control; Switch-to-ground relay control; Relay circuit failures; Relay circuit failure
              #1; Relay circuit failure #2; Relay circuit failure #3; Relay circuit failure #4;
              Relay circuit failure #5; Relay circuit failure #6; Troubleshooting a shorted load;
              relay winding resistance readings.
            </p>
            <p>
              <strong>Lesson 42 [6 Pages]</strong>
              <br />
              INTRODUCTION TO SEMICONDUCTOR PRINCIPLES, PART 1 OF 2 It's about time we got this far;
              Electric current in circuits; What does a VET need to know; The history of solid state
              electronics; It's time for atoms . . . again; Covalent bonding; Doping semiconductor
              material, Adding impurities; Creating N type material, Creating P type material; Holes
              in P type material.
            </p>
            <p>
              <strong>Lesson 43 [6 Pages]</strong>
              <br />
              SEMICONDUCTOR PRINCIPLES, PART 2 OF 2 Lesson introduction; Electrons and Holes;
              Creating a PN junction diode; Semiconductor diode terminology; Forward bias turns
              diode ON; Diode schematic symbol forward bias; Leakage current in PN junctions; Diode
              schematic symbol reversed biased; Introduction to graphs; Diode forward and reverse
              bias graph; Lesson summary.
            </p>
            <p>
              <strong>Lesson 44 [6 Pages]</strong>
              <br />
              TROUBLESHOOTING DIODES Lesson introduction; Testing diodes; Setting Up analog
              ohmmeter; Testing diodes with analog ohmmeter; Applying reverse bias turns diode OFF;
              Applying forward bias turns diode ON; Ohmmeter power testing diodes; Abnormal ohmmeter
              readings of diodes; Testing diodes with DMM's diode test; A practical use of a diode;
              Diode ratings; How diodes fail; Light emitting diodes; Lesson summary.
            </p>
            <p>REVIEW TEST 41-44 (20 Questions)</p>
            <p>
              <strong>Lesson 45 [6 Pages]</strong>
              <br />
              TRANSISTOR, PART 1, THE NPN TRANSISTOR Lesson introduction; basic transistors; The NPN
              transistor; Forward bias current in a NPN transistor; Collector current in a NPN
              transistor; Voltage and current in a NPN transistor; NPN transistor in a circuit; NPN
              transistor in a digital signal circuit; Testing the NPN transistor circuit; Transistor
              static test - analog ohmmeter; Transistor dynamic tests - digital voltmeter.
            </p>
            <p>
              <strong>Lesson 46 [6 Pages]</strong>
              <br />
              TRANSISTORS, PART 2, THE PNP TRANSISTOR The PNP transistor; PNP bias arrangement;
              Collector current in a PNP transistor; Voltage and current in a PNP transistor; PNP
              transistor in a circuit; PNP transistor in a digital signal circuit; Phase shift in
              transistor circuits; Transistor amplification; Transistor static test - analog
              ohmmeter; Transistor dynamic tests - with DMM; PNP versus NPN.
            </p>
            <p>
              <strong>Lesson 47 5 Pages]</strong>
              <br />
              SEMICONDUCTOR APPLICATIONS IN VEHICLE ELECTRONICS Lesson introduction; Zener diodes;
              Zener diode in a voltage regulation circuit; Why test a 5 volt reference circuit; How
              to test a 5 volt reference circuit; How a 5 volt reference circuit fails; What to do?
              Introduction to the PCM schematic; 5 volt reference voltage in a PCM.
            </p>
            <p>
              <strong>Lesson 48 [6 Pages]</strong>
              <br />
              SPIKE VOLTAGE SUPPRESSION &amp; INTRODUCTION TO LAB SCOPES Lesson introduction; The
              spike suppression diode; spike suppression diode review; spike suppression diodes and
              driver; Spike suppression in injector circuits; Lab Scope Terminology; Basic injector
              wave form; Analyzing basic injector wave form; Spike suppression diode in relay
              circuits; Spike suppression resistors in relays.
            </p>
            <p>REVIEW TEST 45-48 (15 Questions)</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Lessons 49-60 Overview</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>You can buy Lessons 49-60 only, in print.</p>
            <p>
              <Button
                href="http://www.1shoppingcart.com/app/netcart.asp?merchantID=177&PRODUCTID=22715"
                text="Buy Lessons 49-60 only - $69"
                type="secondary"
              />
              <span className="meta button-meta"></span>
            </p>
            <p>
              <strong>Lesson 49 [6 Pages]</strong>
              <br />
              POLARITY SENSING DIODES &amp; INTRO.. TO VEHICLE COMPUTERS Polarity sensing diodes;
              The polarity sensing diode; Polarity sensing diode in injector circuits; Polarity
              sensing diode in relay circuits; Analyzing TTS readings with polarity sensing diode;
              Schottky diode; Polarity sensing diode in computers; B+ supply to computers; The
              ground side of computers; Should a VET repair computers?; The computer brain.
            </p>
            <p>
              <strong>Lesson 50 [6 Pages]</strong>
              <br />
              DIGITAL ELECTRONICS, PART 1 OF 4 Lesson introduction; Analog vs. digital electronics;
              Analog voltage; Measuring analog voltage; Using analog voltage to control a lamp;
              Digital voltage; Using a digital signal to control a lamp; Measuring digital signals;
              Digital logic probes (DLP); INTRO.. to DLPs; DLP features; DLP switch settings; DLP
              voltage thresholds; basic DC voltage checks with a DLP.
            </p>
            <p>
              <strong>Lesson 51 [6 Pages]</strong>
              <br />
              DIGITAL ELECTRONICS, PART 2 OF 4 Lesson introduction; Numbering systems; What is a
              logic circuit?; Logic formats and logic levels; The logic gate function; Logic gate
              symbol and truth table; Actual circuit of a logic gate; Troubleshooting a logic gate;
              Logic gate in a circuit; The NOT function; The NOT gate logic symbol and truth table;
              Actual circuit of a NOT gate; NOT gate in a circuit in a circuit; Troubleshooting a
              NOT gate circuit; The AND function; AND gate circuit.
            </p>
            <p>
              <strong>Lesson 52 [6 Pages]</strong>
              <br />
              DIGITAL ELECTRONICS, PART 3 Lesson introduction; The NAND gate function; NAND gate
              logic symbol and truth table; Actual circuit of a NAND gate; Quad driver NAND gate
              operation; Troubleshooting a quad driver circuit; Common quad driver failures; Testing
              quad driver resistance; The OR function; The OR gate symbol and truth table; Actual
              circuit of an OR gate; OR gate in a circuit.
            </p>
            <p>Review Test 49-52 (14 Questions)</p>
            <p>
              <strong>Lesson 53 [6 Pages]</strong>
              <br />
              DIGITAL ELECTRONICS, PART 4 OF 4 Lesson introduction; INTRO.. to integrated circuits;
              Basic computer block diagram; The CPU; ROM, RAM, PROM memory; Analog to digital
              conversion; Input/Output IC; Integrated circuit failures; Ground straps prevent ESD,
              The thermal curve concept; Artificially applying the thermal curve; Surface mount
              technology; Computer personality; Examples of computer personality.
            </p>
            <p>
              <strong>Lesson 54 [6 Pages]</strong>
              <br />
              VOLTAGE DIVIDER CIRCUITS &amp; CRITICAL VOLTAGE MEASUREMENT Lesson introduction; Two
              resistor "fixed" voltage divider; Two resistor "dynamic" voltage divider; Three
              resistor "fixed" voltage divider; Current problems with voltage dividers; DMM loading
              problem with voltage dividers; Critical voltage analysis; Applying Cv analysis; Fat
              chance; When Cv is B+; When Cv is simply too high; When Cv is zero volt; When Cv is
              simply too low; How much can Cv reading deviate? When Cv test point reads zero volts;
              How to use Cv analysis.
            </p>
            <p>
              <strong>Lesson 55 [6 Pages]</strong>
              <br />
              POSITION SENSOR CIRCUITS PART 1 OF 2 Introduction; The potentiometer principle;
              Potentiometer failures; Throttle position sensors; Adding voltage to the TP sensor
              circuit; Significance of analog sensor signal voltage; Troubleshooting there TP sensor
              circuit; Analyzing incorrect TP signal voltage; Sweeping a TP sensor; TP sensor
              diagnostic trouble codes.
            </p>
            <p>
              <strong>Lesson 56 [6 Pages]</strong>
              <br />
              POSITION SENSORS, PART 2 OF 2 Completing the TP sensor circuit; TP sensor noise filter
              circuit; Noise filter networks, The analog to digital converter; Labeling logic
              condition in logic diagrams; Reading analog data from the AD/C; Analog input circuit
              failures; TP signal drop-out problem; Actual TP problems and solutions; Scan tools;
              Examples of scan tool troubleshooting; Fuel level sensor; EGR feedback voltage;
              summarizing the last two lessons.
            </p>
            <p>REVIEW TEST 53-56 (21 Questions)</p>
            <p>
              <strong>Lesson 57 [6 Pages]</strong>
              <br />
              SWITCH POSITION SENSING &amp; INTRO.. TO TEMPERATURE SENSING Switch position sensors;
              Input/output IC inside the PCM; Troubleshooting switch-to-ground sensing;
              Troubleshooting 3rd gear switch; Switch to voltage position sensing; Processing A/C
              switch input; Troubleshooting switch-to-voltage sensing; Introduction to temperature
              measurement; Temperature sensing methods; Thermister temperature sensors; Principle of
              Thermister operation.
            </p>
            <p>
              <strong>Lesson 58 [6 Pages]</strong>
              <br />
              ANALOG SENSOR TROUBLESHOOTING Lesson introduction; Engine coolant sensor circuit;
              Basic ECT circuit; ECT circuit for OBD-II, ECT strategy; DTC sensor graph; Sample ECT
              sensor graph; ECT sensor DTC graph analysis; Troubleshooting ECT circuit; Check ECT
              resistance or voltage/: Two NTC temperature sensor applications; Overview of the SI
              system of measurement; Basic pressure measurement terminology.
            </p>
            <p>
              <strong>Lesson 59 [8 Pages]</strong>
              <br />
              PRESSURE SENSING AND OXYGEN SENSORS Lesson introduction; Manifold absolute pressure
              sensor; MAP technology yesterday and tomorrow; MAP operating strategy; Basic MAP
              sensor circuit; MAP sensor DTC graph; MAP sensor circuit troubleshooting; Introduction
              to oxygen sensors; Operating principle of oxygen sensor; Zro2 sensor circuit in
              OBD-II; Zro2 sensor bias voltage; How to measure O2 bias voltage; Zro2 sensor response
              and driveability; Running lean but no lean DTC; Running rich but no rich DTC.
            </p>
            <p>
              <strong>Lesson 60 "The Finale" [8 Pages]</strong>
              <br />
              TROUBLESHOOTING COMPUTER CONTROLLED ACTUATOR CIRCUITS Welcome to Lesson 60; Switch to
              ground computer control basics; Switch to ground controlled solenoid circuit; When
              switch to ground reading is not normal; Resistance test a coil or solenoid; Current
              test a coil; Check the spike suppression diode; Injector drivers - pulsed coil
              operation; Coil control with pulse width modulation; Examples of switch to ground
              pulse width modulated control; Switch to voltage computer control basics; Switch to
              voltage computer controlled solenoid circuit; When switch to voltage Cv reading is not
              normal, Example of switch to voltage pulse width modulated control; Problems
              controlling DC motors; Load testing actuators; Computer control of relay circuits;
              Congratulations on completing 60 lessons.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <hr />
      <WhatNext kits={true} />
    </div>
  </Layout>
)

export default SixtyLessons
